import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

import Wrapper from './pages/Wrapper';
import HomePage from './pages/HomePage';
import AboutMe from './pages/AboutMe';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          <Route index element={<HomePage />} />
          <Route path="/me" element={<AboutMe />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
