import { Typography, Grid } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

const HomePage = () => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  console.log({ isXsScreen });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        minHeight: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          minHeight: 'max(50vh, 400px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        {isXsScreen ? (
          <Typography style={{ fontWeight: 400, position: 'relative' }}>
            <span style={{ fontSize: '7rem' }}>M</span>
            <span style={{ fontSize: '8rem' }}>i</span>
            <span style={{ fontSize: '8rem' }}>n</span>
            <span style={{ fontSize: '8rem' }}>t</span>
            <span style={{ fontSize: '8rem' }}>u</span>

            <Typography
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                textAlign: 'center',
                fontSize: '2rem',
                fontFamily: 'Playfair',
              }}
            >
              JUPALLY
            </Typography>
          </Typography>
        ) : (
          <Typography style={{ fontWeight: 400, position: 'relative' }}>
            <span style={{ fontSize: '10rem' }}>M</span>
            <span style={{ fontSize: '11rem' }}>i</span>
            <span style={{ fontSize: '11rem' }}>n</span>
            <span style={{ fontSize: '11rem' }}>t</span>
            <span style={{ fontSize: '11rem' }}>u</span>

            <Typography
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                textAlign: 'center',
                fontSize: '2rem',
                fontFamily: 'Playfair',
              }}
            >
              JUPALLY
            </Typography>
          </Typography>
        )}
      </div>

      <div>
        <Grid container style={{ width: '100%', paddingBottom: '2rem' }}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1rem',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Open Sans',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '1.2rem',
                paddingBottom: '1rem',
              }}
            >
              FREELANCER
            </Typography>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontSize: '1rem',
                width: '280px',
                textAlign: 'center',
              }}
            >
              Championing personalized service with adaptability and a dedication to client
              satisfaction
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1rem',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Open Sans',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '1.2rem',
                paddingBottom: '1rem',
              }}
            >
              DEVELOPER
            </Typography>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontSize: '1rem',
                width: '280px',
                textAlign: 'center',
              }}
            >
              Crafting dynamic and efficient web applications. Skilled in React.js and Node.js.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1rem',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Open Sans',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '1.2rem',
                paddingBottom: '1rem',
              }}
            >
              DESIGNER
            </Typography>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontSize: '1rem',
                width: '280px',
                textAlign: 'center',
              }}
            >
              Creating captivating visual experiences. Proficient in Illustrator, Photoshop, and
              Blender.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HomePage;
