import { Grid, Typography } from '@mui/material';
import './index.css';

import { Box, Link } from '@mui/material';
import { Outlet } from 'react-router';
import { WavingHandOutlined } from '@mui/icons-material';

const navItems = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'Contact',
    link: '/contact',
  },
];

const NavBar = () => {
  return (
    <nav
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: '0 2rem',
        boxSizing: 'border-box',
        position: 'fixed',
        height: '3rem',
        maxWidth: 'min(calc(100vw - 8px), 1192px)',
        marginLeft: '4px',
        marginRight: '4px',
        backgroundColor: 'rgb(254, 253, 247)',
        zIndex: 1,
      }}
    >
      <Typography
        align="left"
        style={{
          fontSize: '1.5rem',
          fontFamily: 'Playfair',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'rgb(82, 68, 59)',
          fontStyle: 'italic',
        }}
        component={Link}
        href="/"
      >
        <span style={{ fontSize: '1.8rem' }}>m</span>
        <span style={{ fontSize: '2rem' }}>j</span>
      </Typography>

      <div style={{ display: 'flex' }}>
        {/* {navItems.map((navItem, index) => {
          return (
            <Box
              key={'nav-item-' + index}
              style={{
                marginLeft: '2rem',
                textTransform: 'none',
                textDecoration: 'none',
                color: 'rgb(82, 68, 59)',
              }}
              component={Link}
              href={navItem.link}
            >
              <Typography
                style={{ fontSize: '1.1rem', fontFamily: 'Open Sans', margin: '0px 1rem' }}
              >
                {navItem.label}
              </Typography>
            </Box>
          );
        })} */}
        <Box
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            textTransform: 'none',
            color: 'rgb(82, 68, 59)',
          }}
          component={Link}
          href={'/me'}
        >
          <WavingHandOutlined className="WavingHand" style={{ fontSize: '1.5rem' }} />
        </Box>
      </div>
    </nav>
  );
};

const Wrapper = () => {
  return (
    <div
      className="Wrapper"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        maxWidth: '1200px',
        padding: '1rem',
        margin: 'auto',
        overflow: 'auto',
      }}
    >
      <NavBar />
      <Outlet />
    </div>
  );
};

export default Wrapper;
